import axios from "axios";

export default class LogpdfService {
  getLogpdfs() {
    const url = process.env.VUE_APP_APIURL + `logpdfs`;
    return axios.get(url).then((res) => res.data.data);
  }

  getLogpdf(logpdf) {
    const url = process.env.VUE_APP_APIURL + `/logpdfs/${logpdf.id_log_pdf}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateLogpdf(logpdf) {
    const url = process.env.VUE_APP_APIURL + `/logpdfs/${logpdf.id_log_pdf}`;
    return axios.put(url, logpdf).then((res) => res.data.data);
  }

  createLogpdf(logpdf) {
    const url = process.env.VUE_APP_APIURL + `/logpdfs`;
    return axios.post(url, logpdf).then((res) => res.data.data);
  }

  deleteLogpdf(logpdf) {
    const url = process.env.VUE_APP_APIURL + `/logpdfs/${logpdf.id_log_pdf}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}
