
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import robotoNormal from '@/assets/fonts/Roboto-Thin-normal.js'
import robotoRegular from '@/assets/fonts/Roboto-Thin-regular.js'
import ServiceReportService from "../services/service-report-service";
import LogpdfService from "../services/logpdf-service";

export default {
    created() {
        this.serviceReportService = new ServiceReportService();
        this.logpdfService = new LogpdfService();
    },
    mounted() {
        this.createpdf();
    },
    methods: {
        createpdf() {
            this.logpdfService.getLogpdfs().then(data => {
                data.forEach(element => {
                    var id_orden_trabajo = element.id_orden_trabajo;
                    this.serviceReportService
                        .getReporteServiciosWeb(id_orden_trabajo)
                        .then((dataReporte) => {
                            const doc = new jsPDF('portrait', 'mm', 'a4');
                            doc.addFileToVFS('Roboto-normal1.ttf', robotoNormal)
                            doc.addFileToVFS('Roboto-regular.ttf', robotoRegular)
                            doc.setFont("RobotoRegular");

                            doc.setDrawColor("35B729");
                            doc.setLineWidth(0);
                            doc.setFillColor("35B729");
                            doc.triangle(200, 0, 20, 0, 120, 10, 'FD');


                            doc.setDrawColor("F80202");
                            doc.setLineWidth(0);
                            doc.setFillColor("F80202");
                            doc.triangle(0, 0, 0, 3, 80, 10, 'FD');
                            doc.triangle(140, 0, 0, 0, 80, 10, 'FD');

                            doc.setDrawColor("004EFF");
                            doc.setLineWidth(0);
                            doc.setFillColor("004EFF");
                            doc.triangle(120, 0, 20, 0, 60, 5, 'FD');

                            var imageurl = dataReporte["orden_trabajo"]["logo_empresa"];

                            doc.text("Reporte de Servicio", 155, 15, { align: "left" });
                            var img = new Image;
                            img.src = imageurl;
                            // img.src = imgUrl; //Dev Local
                            doc.addImage(img, 5, 5, 50, 20);
                            doc.setFontSize(9);

                            doc.setFillColor("E2F7DA");
                            doc.rect(5, 30, 200, 28, 'F');

                            doc.setFont("RobotoNormal");
                            doc.text("Empresa: ", 8, 35, { align: "left" });

                            doc.setFont("RobotoRegular");
                            doc.text(dataReporte["orden_trabajo"]["empresa"] ?? "", 22, 35, { align: "left" });

                            doc.setFont("RobotoNormal");
                            doc.text("Cliente: ", 8, 40, { align: "left" });

                            doc.setFont("RobotoRegular");
                            doc.text(dataReporte["orden_trabajo"]["cliente"] ?? "", 20, 40, { align: "left" });

                            doc.setFont("RobotoNormal");
                            doc.text("Atención: ", 8, 45, { align: "left" });

                            doc.setFont("RobotoRegular");
                            doc.text(dataReporte["orden_trabajo"]["atencion"] ?? "", 22, 45, { align: "left" });

                            doc.setFont("RobotoNormal");
                            doc.text("Proyecto: ", 8, 50, { align: "left" });

                            doc.setFont("RobotoRegular");
                            doc.text((dataReporte["orden_trabajo"]["proyecto"] ?? ""), 22, 50, { align: "left" });

                            doc.setFont("RobotoNormal");
                            doc.text("Responsable: ", 8, 55, { align: "left" });

                            doc.setFont("RobotoRegular");
                            doc.text((dataReporte["orden_trabajo"]["responsable"] ?? ""), 28, 55, { align: "left" });

                            doc.setFont("RobotoNormal");
                            doc.text("Orden de Trabajo: ", 110, 35, { align: "left" });

                            doc.setFont("RobotoRegular");
                            doc.text((dataReporte["orden_trabajo"].folio ?? "").toString(), 136, 35, { align: "left" });

                            doc.setFont("RobotoNormal");
                            doc.text("Fecha de órden de trabajo: ", 110, 40, { align: "left" });

                            doc.setFont("RobotoRegular");
                            doc.text((dataReporte["orden_trabajo"]["fecha"] ?? ""), 148, 40, { align: "left" });

                            doc.setFont("RobotoNormal");
                            doc.text("Tipo de Mantenimiento: ", 110, 45, { align: "left" });

                            doc.setFont("RobotoRegular");
                            doc.text((dataReporte["orden_trabajo"]["tipo_mantenimiento"] ?? ""), 144, 45, { align: "left" });

                            doc.setFont("RobotoNormal");
                            doc.text("Clasificación: ", 110, 50, { align: "left" });

                            doc.setFont("RobotoRegular");
                            doc.text((dataReporte["orden_trabajo"]["tipo_mayor_menor"] ?? ""), 130, 50, { align: "left" });

                            doc.setFont("RobotoNormal");
                            doc.text("Referencia: ", 110, 55, { align: "left" });

                            doc.setFont("RobotoRegular");
                            doc.text((dataReporte["orden_trabajo"]["referencia"] ?? ""), 126, 55, { align: "left" });

                            let value = 65;

                            if (dataReporte.orden_trabajo["origen_orden_trabajo_clave"] == "RF") {

                                doc.setFillColor("EBF1F2");
                                doc.rect(5, value + 5, 200, 24, 'F');
                                doc.setFontSize(12);

                                doc.text("Reporte de Falla: " + (dataReporte.origen_orden_trabajo[0].folio ?? ""), 5, value, { align: "left" });
                                value += 10;
                                doc.setFontSize(9);

                                doc.setFont("RobotoNormal");
                                doc.text("Fecha del reporte: ", 8, value, { align: "left" });

                                doc.setFont("RobotoRegular");
                                doc.text((dataReporte.origen_orden_trabajo[0].fecha ?? ""), 34, value, { align: "left" });

                                value += 5;

                                doc.setFont("RobotoNormal");
                                doc.text("Título: ", 8, value, { align: "left" });

                                doc.setFont("RobotoRegular");
                                doc.text((dataReporte.origen_orden_trabajo[0].titulo ?? ""), 18, value, { align: "left" });

                                value += 5;
                                doc.setFont("RobotoNormal");
                                doc.text("Descripción: ", 8, value, { align: "left" });

                                doc.setFont("RobotoRegular");
                                doc.text((dataReporte.origen_orden_trabajo[0].descripcion ?? ""), 26, value, { align: "left" });

                                value += 5;
                                doc.setFont("RobotoNormal");
                                doc.text("Reportado por: ", 8, value, { align: "left" });

                                doc.setFont("RobotoRegular");
                                doc.text((dataReporte.origen_orden_trabajo[0].usuario_reporta ?? ""), 30, value, { align: "left" });
                                value += 10;

                                var reportefallas = dataReporte.reportefallaspreview.filter(ele => ele.id_tipo_archivo === 6);
                                if (reportefallas.length > 0) {
                                    var countfalla = Number((reportefallas.length / 4).toFixed(2));

                                    let fallaposicion = value;
                                    doc.setFontSize(9);
                                    doc.setFont("RobotoNormal");
                                    reportefallas.forEach((reporte, index) => {
                                        var img = new Image;
                                        img.src = (reporte["url"] ?? "");
                                        // img.src = imgUrl; //Dev Local

                                        let desimg = this.truncate((reporte["descripcion"] ?? ""), 24, '...');
                                        let usuario_reporta = this.truncate((reporte["usuario_reporta"] ?? ""), 24, '...');
                                        let fecha_reg = this.truncate((reporte["fecha_reg"] ?? ""), 24, '...');
                                        index += 1;

                                        if ((index % 4) == 1) {
                                            doc.addImage(img, 8, fallaposicion, 40, 40);
                                            doc.text(desimg, 8, fallaposicion + 45, { align: "left" });
                                            doc.text(usuario_reporta, 8, fallaposicion + 50, { align: "left" });
                                            doc.text(fecha_reg, 8, fallaposicion + 55, { align: "left" });
                                        }

                                        if ((index % 4) == 2) {
                                            doc.addImage(img, 60, fallaposicion, 40, 40);
                                            doc.text(desimg, 60, fallaposicion + 45, { align: "left" });
                                            doc.text(usuario_reporta, 60, fallaposicion + 50, { align: "left" });
                                            doc.text(fecha_reg, 60, fallaposicion + 55, { align: "left" });
                                        }

                                        if ((index % 4) == 3) {
                                            doc.addImage(img, 110, fallaposicion, 40, 40);
                                            let desimg = this.truncate((reporte["descripcion"] ?? ""), 28, '...');
                                            doc.text(desimg, 110, fallaposicion + 45, { align: "left" });
                                            doc.text(usuario_reporta, 110, fallaposicion + 50, { align: "left" });
                                            doc.text(fecha_reg, 110, fallaposicion + 55, { align: "left" });
                                        }

                                        if ((index % 4) == 0) {
                                            doc.addImage(img, 160, fallaposicion, 40, 40);
                                            let desimg = this.truncate((reporte["descripcion"] ?? ""), 28, '...');
                                            doc.text(desimg, 160, fallaposicion + 45, { align: "left" });
                                            doc.text(usuario_reporta, 160, fallaposicion + 50, { align: "left" });
                                            doc.text(fecha_reg, 160, fallaposicion + 55, { align: "left" });
                                            fallaposicion += 58;
                                        }
                                    });
                                    value += ((countfalla) > 1 ? fallaposicion - 35 : 65);
                                }
                            }

                            dataReporte.equipos.forEach((element) => {
                                doc.setFontSize(12);
                                doc.setFont("RobotoRegular");
                                var alias = "";
                                if ((element.info_equipo[0]["alias"] ?? "").length > 0) {
                                    alias = "(" + (element.info_equipo[0]["alias"] ?? "") + ")";
                                }
                                doc.text((element.info_equipo[0]["nombre_equipo"] ?? "") + " " + alias, 8, value, { align: "left" });

                                value += 6;
                                doc.setFontSize(9);
                                var yaccion = 8;
                                if ((element.info_equipo[0]["iniciales"] ?? "").length > 0) {
                                    doc.text((element.info_equipo[0]["iniciales"] ?? "") + " ", 8, value, { align: "left" });
                                    yaccion = (element.info_equipo[0]["iniciales"] ?? "").length + 10;
                                }
                                if ((element.info_equipo[0]["accion_texto"] ?? "").length > 0) {
                                    doc.setFont("RobotoNormal");
                                    doc.text("  • Acción: ", yaccion, value, { align: "left" });
                                    yaccion += 14;
                                    doc.setFont("RobotoRegular");
                                    doc.text(" " + (element.info_equipo[0]["accion_texto"] ?? "") + " ", yaccion, value, { align: "left" });
                                    yaccion += (element.info_equipo[0]["accion_texto"] ?? "").length + 6;
                                }
                                if ((element.info_equipo[0]["comentario_mtto"] ?? "").length > 0) {
                                    doc.setFont("RobotoNormal");
                                    doc.text("  • Comentarios: ", yaccion, value, { align: "left" });
                                    yaccion += 22;
                                    doc.setFont("RobotoRegular");
                                    doc.text(" " + (element.info_equipo[0]["comentario_mtto"] ?? "") + " ", yaccion, value, { align: "left" });
                                }
                                value += 2;
                                doc.setFontSize(9);

                                doc.setFillColor("EBF1F2");
                                doc.rect(5, value, 200, element.dataSourceEquipmentFields1.length * 5.8, 'F');
                                let equipodetalle = value;

                                element.dataSourceEquipmentFields1.forEach(detalle1 => {
                                    value += 5;
                                    var ydetalle1 = 8;
                                    doc.setFont("RobotoNormal");
                                    doc.text((detalle1["name"] ?? "") + ": ", ydetalle1, value, { align: "left" });

                                    ydetalle1 += (detalle1["name"] ?? "").length + 6;

                                    doc.setFont("RobotoRegular");
                                    doc.text((detalle1["value"] ?? ""), ydetalle1, value, { align: "left" });
                                });
                                value = equipodetalle;
                                element.dataSourceEquipmentFields2.forEach(detalle2 => {
                                    var ydetalle2 = 50;
                                    value += 5;

                                    doc.setFont("RobotoNormal");
                                    doc.text((detalle2["name"] ?? "") + ": ", ydetalle2, value, { align: "left" });

                                    ydetalle2 += (detalle2["name"] ?? "").length + 6;

                                    let valuedetalle = this.truncate((detalle2["value"] ?? ""), 10, '...')
                                    doc.setFont("RobotoRegular");
                                    doc.text(valuedetalle, ydetalle2, value, { align: "left" });
                                });
                                value = equipodetalle;
                                element.dataSourceEquipmentFields3.forEach(detalle3 => {
                                    var ydetalle3 = 100;
                                    value += 5;

                                    doc.setFont("RobotoNormal");
                                    let valuedetallename = this.truncate((detalle3["name"] ?? ""), 24, '...')
                                    doc.text(valuedetallename + ": ", ydetalle3, value, { align: "left" });

                                    ydetalle3 += (detalle3["name"] ?? "").length + 7;

                                    doc.setFont("RobotoRegular");
                                    let valuedetalle = this.truncate((detalle3["value"] ?? ""), 24, '...')
                                    doc.text(" " + valuedetalle, ydetalle3, value, { align: "left" });
                                });
                                value = equipodetalle;
                                element.dataSourceEquipmentFields4.forEach(detalle4 => {
                                    var ydetalle4 = `160`;
                                    value += 5;

                                    let valuedetallename = this.truncate((detalle4["name"] ?? ""), 24, '...')

                                    doc.setFont("RobotoNormal");
                                    doc.text(valuedetallename + ": ", ydetalle4, value, { align: "left" });

                                    let valuedetalle = this.truncate((detalle4["name"] ?? ""), 24, '...')
                                    ydetalle4 += (detalle4["name"] ?? "").length + 7;

                                    doc.setFont("RobotoRegular");
                                    doc.text(valuedetalle, ydetalle4, value, { align: "left" });
                                });

                                value += 5;
                                doc.setFontSize(9);
                                if (element.dataSourceSpecialFields1.length > 0) {
                                    value += 5;

                                    doc.text("Características especiales", 8, value, { align: "left" });
                                    let specialvalue = value;

                                    element.dataSourceSpecialFields1.forEach(special1 => {
                                        var yspecial1 = 8
                                        value += 5;

                                        doc.setFont("RobotoNormal");
                                        let valuespecial1name = this.truncate((special1["name"] ?? ""), 12, '...');
                                        doc.text(valuespecial1name + ": ", yspecial1, value, { align: "left" });

                                        valuespecial1name.includes("...")
                                        yspecial1 += valuespecial1name.includes("...") ? (valuespecial1name.length + 6) : (valuespecial1name.length + 5);

                                        doc.setFont("RobotoRegular");
                                        let valuespecial1 = this.truncate((special1["value"] ?? ""), 39 - yspecial1, '...')
                                        doc.text(" " + valuespecial1, yspecial1, value, { align: "left" });
                                    });

                                    value = specialvalue;
                                    element.dataSourceSpecialFields2.forEach(special2 => {
                                        var yspecial2 = 50;
                                        value += 5;

                                        doc.setFont("RobotoNormal");
                                        let valuespecial2name = this.truncate((special2["name"] ?? ""), 12, '...')
                                        doc.text(valuespecial2name + ": ", yspecial2, value, { align: "left" });

                                        yspecial2 += valuespecial2name.includes("...") ? valuespecial2name.length + 5 : valuespecial2name.length + 3;

                                        doc.setFont("RobotoRegular");
                                        let valuespecial2 = this.truncate((special2["value"] ?? ""), 80 - yspecial2, '...')
                                        doc.text("  " + valuespecial2, yspecial2, value, { align: "left" });
                                    });

                                    value = specialvalue;
                                    element.dataSourceSpecialFields3.forEach(special3 => {
                                        var yspecial3 = 100;
                                        value += 5;

                                        doc.setFont("RobotoNormal");
                                        let valuespecial3name = this.truncate((special3["name"] ?? ""), 12, '...')
                                        doc.text(valuespecial3name + ": ", yspecial3, value, { align: "left" });

                                        yspecial3 += valuespecial3name.includes("...") ? valuespecial3name.length + 7 : valuespecial3name.length + 5;

                                        doc.setFont("RobotoRegular");
                                        let valuespecial3 = this.truncate((special3["value"] ?? ""), 142 - yspecial3, '...')
                                        doc.text(" " + valuespecial3, yspecial3, value, { align: "left" });
                                    });

                                    value = specialvalue;
                                    element.dataSourceSpecialFields4.forEach(special4 => {
                                        var yspecial4 = 160;
                                        value += 5;

                                        doc.setFont("RobotoNormal");
                                        let valuespecial4name = this.truncate((special4["name"] ?? ""), 10, '...')
                                        doc.text(valuespecial4name + ": ", yspecial4, value, { align: "left" });

                                        yspecial4 += valuespecial4name.includes("...") ? valuespecial4name.length + 7 : valuespecial4name.length + 4;

                                        doc.setFont("RobotoRegular");
                                        let valuespecial1 = this.truncate((special4["value"] ?? ""), 192 - yspecial4, '...')
                                        doc.text(valuespecial1, yspecial4, value, { align: "left" });
                                    });

                                    var speciallength = element.dataSourceSpecialFields1.length;
                                    value += speciallength * 3;
                                    value += 5;
                                }

                                doc.text("Tareas y Mediciones", 8, value, { align: "left" });

                                var columnsTareaMedicion = [
                                    { title: "Medición", dataKey: "medicion" },
                                    { title: "Resultado", dataKey: "resultado" },
                                    { title: "Acción", dataKey: "accion" },
                                    { title: "Observaciones", dataKey: "observaciones" },
                                    { title: "Técnico", dataKey: "usuario" },
                                ];
                                value += 5;
                                let hash = {};
                                var array = element.tareasmedicion.filter(o => hash[o.tarea] ? false : hash[o.tarea] = true);
                                value += 5;
                                array.forEach((tarea, indextarea) => {

                                    if (value >= 240) {
                                        doc.addPage();
                                        value = 15; // Restart height position
                                    }
                                    var tareasmedicion = element.tareasmedicion.filter((ele) => ele.tarea === tarea.tarea);

                                    doc.text(tarea.tarea, 8, value, { align: "left" });
                                    value += 5;
                                    let coordenada = 0
                                    let final = 0;

                                    if (indextarea > 0) {
                                        if (doc.autoTable.previous) {
                                            final = value;
                                            coordenada = Number(doc.autoTable.previous.finalY.toFixed());
                                        } else {
                                            coordenada = 10
                                            final = value;
                                        }
                                    } else {
                                        coordenada = value;
                                        final = value;
                                    }

                                    doc.autoTable(columnsTareaMedicion, tareasmedicion, {
                                        rowPageBreak: 'avoid',
                                        pageBreak: 'auto',
                                        finalY: final,
                                        startY: (final),
                                        styles: {
                                            fontSize: 9,
                                            font: 'RobotoNormal'
                                        },
                                        margin: {
                                            left: 8,
                                            right: 8
                                        },
                                        headerStyles: {
                                            font: 'RobotoRegular'
                                        }
                                    });
                                    if (doc.autoTable.previous) {
                                        coordenada = Number(doc.autoTable.previous.finalY.toFixed());
                                    }
                                    value = + (coordenada + 8);
                                });

                                var evidenciasimg = element.evidenciaspreview.filter(ele => ele.tipo_archivo === "Imagen");

                                if (evidenciasimg.length > 0) {
                                    value += 5;
                                    doc.text("Evidencias", 8, value, { align: "left" });
                                    value += 5;
                                    if (value >= 240) {
                                        doc.addPage();
                                        value = 15; // Restart height position
                                    }

                                    let imgposicion = value;
                                    evidenciasimg.forEach((imgEvidencia, index) => {
                                        var img = new Image;
                                        img.src = (imgEvidencia["archivo"] ?? "");
                                        // img.src = imgUrl; //Dev Local

                                        var imgAntes = new Image;
                                        var imgCotizacion = new Image;

                                        if (imgEvidencia.antes_mtto) {
                                            imgAntes.src = require("../assets/antes_mtto.png");
                                        } else {
                                            imgAntes.src = require("../assets/despues_mtto.png");
                                        }

                                        imgCotizacion.src = require("../assets/cotizacion.png");

                                        doc.setFont("RobotoNormal");

                                        index += 1;
                                        if (imgposicion >= 240) {
                                            doc.addPage();
                                            imgposicion = 15; // Restart height position
                                        }

                                        if ((index % 4) == 1) {
                                            doc.addImage(img, 8, imgposicion, 40, 40);
                                            let desimg = this.truncate((imgEvidencia["descripcion"] ?? ""), 28, '...');
                                            doc.text(desimg, 8, imgposicion + 45, { align: "left" });
                                            doc.addImage(imgAntes, 8, imgposicion + 46);
                                            if (imgEvidencia.cotizacion) {
                                                doc.addImage(imgCotizacion, 8, imgposicion + 51);
                                            }
                                        }

                                        if ((index % 4) == 2) {
                                            doc.addImage(img, 60, imgposicion, 40, 40);
                                            let desimg = this.truncate((imgEvidencia["descripcion"] ?? ""), 28, '...');
                                            doc.text(desimg, 60, imgposicion + 45, { align: "left" });
                                            doc.addImage(imgAntes, 60, imgposicion + 46);
                                            if (imgEvidencia.cotizacion) {
                                                doc.addImage(imgCotizacion, 60, imgposicion + 51);
                                            }
                                        }

                                        if ((index % 4) == 3) {
                                            doc.addImage(img, 110, imgposicion, 40, 40);
                                            let desimg = this.truncate((imgEvidencia["descripcion"] ?? ""), 28, '...');
                                            doc.text(desimg, 110, imgposicion + 45, { align: "left" });
                                            doc.addImage(imgAntes, 110, imgposicion + 46);
                                            if (imgEvidencia.cotizacion) {
                                                doc.addImage(imgCotizacion, 110, imgposicion + 51);
                                            }
                                        }

                                        if ((index % 4) == 0) {
                                            doc.addImage(img, 160, imgposicion, 40, 40);
                                            let desimg = this.truncate((imgEvidencia["descripcion"] ?? ""), 28, '...');
                                            doc.text(desimg, 160, imgposicion + 45, { align: "left" });
                                            doc.addImage(imgAntes, 160, imgposicion + 46);
                                            if (imgEvidencia.cotizacion) {
                                                doc.addImage(imgCotizacion, 160, imgposicion + 51);
                                            }
                                            imgposicion += 58;
                                        }

                                    });
                                    value = imgposicion + 62;
                                }
                                if (value >= 240) {
                                    doc.addPage();
                                    value = 15; // Restart height position
                                }
                            });

                            if ((dataReporte["orden_trabajo"]["comentario"] ?? "").length > 0) {
                                value += 5;
                                if (value >= 240) {
                                    doc.addPage();
                                    value = 15; // Restart height position
                                }
                                doc.setFontSize(14);
                                doc.setFont("RobotoRegular");
                                doc.text("Comentarios de la orden de trabajo", 8, value, { align: "left" });
                                value += 5;
                                doc.setFontSize(9);
                                doc.setFont("RobotoNormal");
                                var comentario = doc.splitTextToSize((dataReporte["orden_trabajo"]["comentario"] ?? ""), 180);
                                doc.text(comentario, 8, value, { align: "left" });
                                value += 15;
                            }
                            if ((dataReporte["orden_trabajo"]["firma"] ?? "").length > 0) {
                                doc.setFontSize(14);
                                doc.setFont("RobotoRegular");
                                doc.text("Firmas", 8, value, { align: "left" });
                                value += 5;
                                if (value >= 240) {
                                    doc.addPage();
                                    value = 15; // Restart height position
                                }
                                var imgfirmaurl = (dataReporte["orden_trabajo"]["firma"] ?? "");
                                var imgfirma = new Image;
                                imgfirma.src = imgfirmaurl;
                                // imgfirma.src = imgUrl; //Dev Local
                                doc.addImage(imgfirma, 8, value, 40, 40);
                                value += 5;
                            }

                            var imglogoapi = require("../assets/logo_apiclix_425.png"); //Dev Local
                            var imgfooter = new Image;
                            imgfooter.src = imglogoapi;

                            const pageCount = doc.internal.getNumberOfPages();


                            for (let i = 1; i <= pageCount; i++) {
                                doc.setPage(i);
                                const pageSize = doc.internal.pageSize;
                                const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
                                const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                                doc.addImage(imgfooter, 8, pageHeight - 10, 15, 4);
                                doc.setFontSize(14);
                                doc.textWithLink('              ', 8, pageHeight - 6, { url: 'https://apiclix.com' });
                                const footer = `Página ${i} de ${pageCount}`;
                                doc.setFontSize(9);
                                doc.setFont("RobotoNormal");
                                doc.text(footer, pageWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 5, { baseline: 'bottom' });
                                doc.textWithLink('apicontrol.mx |', 164, pageHeight - 5, { url: 'https://apicontrol.mx' });
                                doc.textWithLink('apiclix.com', 185, pageHeight - 5, { url: 'https://apiclix.com' });
                            }

                            var formData = new FormData();
                            formData.append('file', doc.output("blob"), id_orden_trabajo);
                            formData.append('id_orden_trabajo', id_orden_trabajo);
                            formData.append('id_usuario', element.id_usuario);

                            this.serviceReportService
                                .uploadfilesendmail(formData).then();
                        });
                });
            });
        },
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    }
};
